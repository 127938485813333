.App-spells {
  min-height: 100vh;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header {
  min-height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-navigation {
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Version {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 5px;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}